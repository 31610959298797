<template>
<v-row class="main-bg-rebel" v-if="product != null">
  <v-col cols="12" sm="11" md="11" lg="9" class="mx-auto">
    <v-card class="px-0" id="dialogProduct" color="transparent" elevation="0">

      <v-row>
        <v-col cols="12" 
              :sm="product.gallery.length == 0 ? '12' : '12'" 
              :md="product.gallery.length == 0 ? '12' : '12'"
              :class="product.gallery.length == 0 ? 'mx-auto' : ''"
               class="pb-0 mt-5" >

          <v-toolbar color="transparent" class="mb-2" elevation="0" v-if="product.categories[0] != null">
            <v-btn @click="goBack()" fab small color="grey darken-3" class="mr-2"
                   title="retour à la page précédente">
              <v-icon color="white">mdi-arrow-left</v-icon>
            </v-btn>
            <span style="font-size:30px;" class="font-dancing">{{ product.categories[0].name }}</span>
            <v-spacer/>
            <v-btn icon outlined large class="mx-1" @click="goToProductBack()" :title="'parcourir les '+ product.categories[0].name">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon outlined large class="mx-1" @click="goToProductNext()" :title="'parcourir les '+ product.categories[0].name">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-toolbar>

          <v-divider class="my-5"/>

          <v-row class="ml-0">
            <v-col cols="12" sm="4" class="pl-0">
                <v-img :src="baseUrl() + product.image" 
                        @click="showDialogImg = true" 
                        class="cursor-pointer" 
                        :class="product.isCircle ? 'img-rounded-dialog' : ''"/>
            </v-col>
            <v-col cols="12" sm="8">
              <v-card-title class="title-dialog-product font-dancing">
                {{ product.name }}
              </v-card-title>
              <v-card-subtitle class="mt-2">{{ product.dimension }}</v-card-subtitle>
              <v-card-text class="pa-5" v-html="nl2br(product.description)"/>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      
      <v-col cols="12" class="mt-5" v-if="product.gallery.length > 0">
        <GalleryGrid :gallery="product.gallery"/>
      </v-col>

      <template v-if="product.videoYT != ''">
        <v-col cols="12" class="d-md-none mt-5 text-center">
          <v-divider class="my-5"></v-divider>
          <iframe width="300" height="170" :src="product.videoYT" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  frameborder="0" allowfullscreen>
          </iframe>
        </v-col>
        <v-col cols="12" class="d-none d-md-inline-block mt-5 text-center">
          <v-divider class="my-5"></v-divider>
          <iframe width="750" height="430" :src="product.videoYT"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  frameborder="0" allowfullscreen>
          </iframe>
        </v-col>
      </template>

      <v-col cols="12" lg="12" class="mx-auto px-0">
      
        <v-divider class="my-5"></v-divider>
        
        <v-card-actions class="pa-3">
          <template v-if="product.stock > 0">
            <span class="font-dancing ml-3" style="font-size:30px;">{{ product.price }}€</span>
            <v-spacer/>
            <v-btn color="green" rounded large dark elevation="0" class="px-4" @click="addToBasket()">
              <v-icon small class="mr-1">mdi-basket-plus</v-icon> Ajouter au panier
            </v-btn>
          </template>
          <template v-else>
            <v-spacer/>
            <small class="mr-3 grey--text">Ce produit n'est plus en stock</small>
          </template>
        </v-card-actions>

        <v-divider v-if="product.reproductible != false" class="my-5"></v-divider>
        
        <!-- <v-card-actions class="pa-3 d-none d-sm-flex" v-if="product.reproductible != false">
          Commander une reproduction sur papier ou sur toile 
          <v-spacer/>
          <v-btn @click="showDialogRepro = true" dark rounded large elevation="0" color="teal" class="ml-5 px-4">
            <v-icon small class="mr-1">mdi-basket-plus</v-icon> Commander une reproduction
          </v-btn>
        </v-card-actions> -->

        <v-row class="my-0">
          <v-col cols="12" class="text-right pr-5" v-if="product.reproductible">
            <v-btn @click="showDialogRepro = true" dark rounded large elevation="0" color="teal" class="px-4">
              <v-icon small class="mr-1">mdi-basket-plus</v-icon> Commander une reproduction
            </v-btn> 
          </v-col>
        </v-row>


        <v-divider class="my-5"></v-divider>
        

        <CommentBlock :productId="product._id"></CommentBlock>
      </v-col>

    </v-card>
  </v-col>

  <v-dialog fullscreen v-model="showDialogImg">
    <v-card color="#1E1E1EF7">
      <v-row class="ma-0 py-0">
        <v-col cols="12" sm="10" md="8" class="mx-auto" style="margin-top:50px;">
          <img :src="baseUrl() + product.image" contain  
                  max-width="100%" max-height="700"
                  style="border-radius:8px; max-height:800px; max-width:100%;" 
                  class="mx-auto my-5 d-flex"
                  :class="product.isCircle ? 'img-rounded-dialog' : ''"/>
        </v-col>
      </v-row>

      <v-toolbar class="px-3 toolbar-popup" style="position:fixed; top:0%;" color="transparent" height="70" elevation="0">
        <v-spacer/>
        <v-btn icon dark outlined @click="showDialogImg = false" >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog> 

  <DialogReproduction :product="product" 
                      :showDialogRepro="showDialogRepro"
                      :closeDialogRepro="closeDialogRepro"></DialogReproduction>

</v-row>
</template>


<style></style>

<script>

//import axios from 'axios'
import router from '../router/router'
import core from '../plugins/core.js'

import GalleryGrid from '@/components/main-layout/GalleryGrid.vue'
import DialogReproduction from '@/components/main-layout/DialogReproduction.vue'
import CommentBlock from '@/components/comment/CommentBlock.vue'

export default {
  name: 'product',
  components: { GalleryGrid, DialogReproduction, CommentBlock },
  data: () => ({
    product: null,
    allProducts: [],
    showDialogImg: false,
    showDialogRepro: false,
    dialogImgUrl: ''
  }),
  async mounted(){
    
    this.init()
    this.$store.dispatch('app/incStat', '/product')

  },
  methods: {
    async init(){
      await this.$store.dispatch('app/fetchEntities', { entityType: "product", query: { _id: this.$route.params.productId } })
      this.product = this.$store.state.app.data.product[0]
      
      if(this.allProducts.length == 0)
      this.allProducts = await this.$store.dispatch('app/fetchEntities', 
                                            { entityType: "product", 
                                              query: { 
                                                public: true, 
                                                categories: { "$in" : this.product.categories[0] != null ? this.product.categories[0]._id : null } 
                                              } 
                                            })

    },
    goToProductBack(){
      let idm = ''
      let idx = ''
      this.allProducts.forEach((prod)=>{
        if(prod._id == this.product._id) idm = idx
        idx = prod._id
      })
      if(idm == '') idm = this.allProducts[this.allProducts.length-1]._id
      this.$router.push("/product/"+idm)
      this.$store.dispatch('app/incStat', '/product')
    },
    goToProductNext(){
      let idm = ''
      let found = false
      this.allProducts.forEach((prod)=>{
        if(found) idm = prod._id
        found = (prod._id == this.product._id)
      })
      if(idm == '') idm = this.allProducts[0]._id
      this.$router.push("/product/"+idm)
      this.$store.dispatch('app/incStat', '/product')
    },
    goToProduct(id){
      this.$router.push("/product/" + id)
    },
    addToBasket(){
      this.$store.dispatch('basket/addProduct', this.product)
      this.$router.push("/panier")
    },
    closeDialogRepro(){ this.showDialogRepro = false },
    goBack(){ router.back() },
    nl2br: function(str, is_xhtml){
      return core.nl2br(str, is_xhtml)
    },
    baseUrl(){ return core.baseUrl() }

  },
  computed: {
  },
  watch: {
      '$route.params.productId': { immediate: true, 
          async handler () {
              this.init()
          }
      },
  }, 
}
</script>
